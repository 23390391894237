import { gql } from '@apollo/client';

import { Client, ClientCRM, ClientMyHome, AdditionalInfo } from '../declarations/common';

interface Pagination {
  nextPage: number;
  page: number;
  perPage: number;
  prevPage: number | null;
  totalDocs: number;
  totalPages: number;
}

export const QUERY_CLIENT_LIST = gql`
  query getCustomersTabInfo($project_id: ID!, $params: PaginatedClientsParams!, $filters: ClientsManagerFilters) {
    getCustomersTabInfo(project_id: $project_id, params: $params, filters: $filters) {
      data {
        _id
        firstName
        lastName
        email
        tel
        appointee {
          id
          firstName
          lastName
          email
          role
          isDisabled
        }
        createdOn
        updatedOn
      }
      paginationInfo {
        totalDocs
        totalPages
        page
        perPage
        prevPage
        nextPage
      }
    }
  }
`;

export interface ResponseQueryClientList {
  getCustomersTabInfo: {
    data: Array<Client>;
    paginationInfo: Pagination;
  };
}

export const QUERY_CLIENT_LIST_MYHOME = gql`
  query getMyHomeTabInfo($project_id: ID!, $params: PaginatedClientsParams!, $filters: ClientsManagerFilters) {
    getMyHomeTabInfo(project_id: $project_id, params: $params, filters: $filters) {
      data {
        _id
        confirmed
        dateOperationFailed
        firstName
        lastName
        lastAccess
        myhomeStatus
        myhome_version
        reserved
        user
      }
      paginationInfo {
        totalDocs
        totalPages
        page
        perPage
        prevPage
        nextPage
      }
    }
  }
`;

export interface ResponseQueryClientListMyHome {
  getMyHomeTabInfo: {
    data: Array<ClientMyHome>;
    paginationInfo: Pagination;
  };
}

export const QUERY_CLIENT_LIST_CRM = gql`
  query getCRMTabInfo($project_id: ID!, $params: PaginatedClientsParams!, $filters: ClientsManagerFilters) {
    getCRMTabInfo(project_id: $project_id, params: $params, filters: $filters) {
      data {
        _id
        firstName
        lastName
        actions {
          _id
          actionName
          actionDate
          category
          vendor
          quote
          note
          deleted
          createdOn
          eventId
        }
        additionalInfo {
          tag
        }
        appointee {
          id
          firstName
          lastName
          email
          role
          isDisabled
        }
        createdOn
        updatedOn
      }
      paginationInfo {
        totalDocs
        totalPages
        page
        perPage
        prevPage
        nextPage
      }
    }
  }
`;

export interface ResponseQueryClientListCRM {
  getCRMTabInfo: {
    data: Array<ClientCRM>;
    paginationInfo: Pagination;
  };
}
// #region ::: QUERY TAB OTHER INFO
export const QUERY_CLIENT_LIST_OTHER_INFO = gql`
  query getOtherInfoTab(
    $project_id: ID!
    $params: PaginatedClientsParams!
    $otherInfoFields: [OtherInfoProjectionFields]
    $filters: ClientsManagerFilters
  ) {
    getOtherInfoTab(project_id: $project_id, params: $params, filters: $filters, otherInfoFields: $otherInfoFields) {
      data
      paginationInfo {
        totalDocs
        totalPages
        page
        perPage
        prevPage
        nextPage
      }
    }
  }
`;
// #endregion

export const QUERY_CLIENT_ADDITIONAL_INFO_BY_NAME = gql`
  query getAdditionalInfoByName($project_id: ID!, $name: String!) {
    getAdditionalInfoByName(project_id: $project_id, name: $name) {
      id
      subOptions {
        section
        subSections
      }
    }
  }
`;

export interface ResponseQueryAdditionalInfo {
  getAdditionalInfoByName: {
    subOptions: AdditionalInfo[];
  };
}

export const QUERY_ALL_CLIENTS_ADDITIONAL_INFO = gql`
  query getAllAdditionalInfoByProject($project_id: ID!) {
    getAllAdditionalInfoByProject(project_id: $project_id) {
      options
      project_id
      label
      tab
      type
    }
  }
`;

export interface ResponseQueryAllClientsAdditionalInfo {
  getAllAdditionalInfoByProject: {
    label: string;
    options: string[];
    project_id: string | string[];
    tab: string;
    type: string;
  }[];
}

export const QUERY_BACK_OFFICE_USERS = gql`
  query getBackOfficeUsers($project_id: ID!, $backOfficeUsersInput: BackOfficeUsersFilterInput) {
    getBackOfficeUsers(project_id: $project_id, backOfficeUsersInput: $backOfficeUsersInput) {
      id
      firstName
      lastName
    }
  }
`;

export interface ResponseQueryBackOfficeUsers {
  getBackOfficeUsers: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
}

export const MUTATION_UPDATE_CLIENT_TAGS = gql`
  mutation updateClientTags($project_id: ID!, $id: ID!, $tags: [String]!) {
    updateClientTags(project_id: $project_id, id: $id, tag: $tags) {
      id
    }
  }
`;

export interface ResponseMutationUpdateClientTags {
  updateClientTags: {
    id: string;
  };
}

export interface ResponseMutationRenewClientReservation {
  renewClientReservation: {
    id: string;
  };
}
