import { createSlice } from '@reduxjs/toolkit';

import { AdditionalInfo, Appointee, SelectedFilters } from '../../declarations/common';
import { thunkAllClientsAdditionalInfo } from '../thunks/thunk.client.list.all.additional-info';
import { thunkProjectActions } from '../thunks/thunk.project.actions';
import { thunkProjectTags } from '../thunks/thunk.project.tags';
import { thunkBackOfficeUsers } from '../thunks/thunk.users.backoffice';

export const sliceAdditionalInfo = createSlice({
  initialState: {} as {
    tags?: AdditionalInfo[];
    actions?: AdditionalInfo[];
    otherInfo?: AdditionalInfo[];
    appointees?: Appointee[];
    loadingTags?: boolean;
    loadingActions?: boolean;
    loadingOtherInfo?: boolean;
    loadingAppointees?: boolean;
    selectedFilters?: SelectedFilters;
    activeFilters?: SelectedFilters;
  },
  name: 'additionalInfo',
  reducers: {
    setActiveFilters: (state, action) => {
      state.activeFilters = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkProjectTags.pending, (state) => {
      state.loadingTags = true;
    });
    builder.addCase(thunkProjectTags.fulfilled, (state, action) => {
      delete state.loadingTags;
      state.tags = action.payload.subOptions;
    });
    builder.addCase(thunkProjectTags.rejected, (state) => {
      delete state.loadingTags;
    });
    builder.addCase(thunkProjectActions.pending, (state) => {
      state.loadingActions = true;
    });
    builder.addCase(thunkProjectActions.fulfilled, (state, action) => {
      delete state.loadingActions;
      state.actions = action.payload.subOptions;
    });
    builder.addCase(thunkProjectActions.rejected, (state) => {
      delete state.loadingActions;
    });
    builder.addCase(thunkAllClientsAdditionalInfo.pending, (state, action) => {
      state.loadingOtherInfo = true;
    });
    builder.addCase(thunkAllClientsAdditionalInfo.fulfilled, (state, action) => {
      delete state.loadingOtherInfo;
      state.otherInfo = action.payload.map(({ label, options }) => ({ section: label, subSections: options }));
    });
    builder.addCase(thunkAllClientsAdditionalInfo.rejected, (state) => {
      delete state.loadingOtherInfo;
    });
    builder.addCase(thunkBackOfficeUsers.pending, (state, action) => {
      state.loadingAppointees = true;
    });
    builder.addCase(thunkBackOfficeUsers.fulfilled, (state, action) => {
      delete state.loadingAppointees;
      const appointees = JSON.parse(JSON.stringify(action.payload));
      state.appointees = appointees.sort((a, b) => `${a.firstName + a.lastName}`.localeCompare(b.firstName + b.lastName));
    });
    builder.addCase(thunkBackOfficeUsers.rejected, (state) => {
      delete state.loadingAppointees;
    });
  },
});
