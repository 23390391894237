import { configureStore } from '@reduxjs/toolkit';

import { sliceEnvironment } from './slice/slice.environment';
import { sliceI18n } from './slice/slice.i18n';
import { sliceAdditionalInfo } from '../@module-clients/redux/selectors/slice.additional-info';
import { sliceClienti } from '../@module-clients/redux/selectors/slice.clienti';
import { sliceDialogs } from '../@module-clients/redux/selectors/slice.dialogs';
import { sliceTimeFrame } from '../@module-clients/redux/selectors/slice.time-frame';

export const createStore = () => {
  const prevColumns = sessionStorage.getItem('columns');
  const prevTags = sessionStorage.getItem('tags');
  const columns = prevColumns ? JSON.parse(prevColumns) : [];
  const tags = prevTags ? JSON.parse(prevTags) : [];

  const store = configureStore({
    reducer: {
      // @ts-ignore
      app: sliceClienti.reducer,
      additionalInfoApp: sliceAdditionalInfo.reducer,
      environment: sliceEnvironment.reducer,
      timeFrame: sliceTimeFrame.reducer,
      dialogs: sliceDialogs.reducer,
      i18n: sliceI18n.reducer,
    },
    preloadedState: {
      app: { columns },
      additionalInfoApp: { tags },
    },
  });
  return store;
};

export const reduxStore = createStore();

reduxStore.subscribe(() => {
  const { app, additionalInfoApp } = reduxStore.getState();
  const { columns } = app;
  const { tags } = additionalInfoApp;
  if (tags && tags.length > 0) sessionStorage.setItem('tags', JSON.stringify(tags));
  if (typeof columns === 'object') sessionStorage.setItem('columns', JSON.stringify(columns));
});
